import React,{useState,useEffect} from "react"
import { navigate} from "gatsby"
import LoaderSVG from '../images/loading.svg'
import { Box } from "@chakra-ui/react"

import { handleAuthentication, getProfile} from '../utils/auth0.js';
import axios  from 'axios';

const Dis = ({email_verified}) => {
  return(
    <>
    {email_verified == null ? 
      <div style={{
        position: "absolute",
        display: "flex",
        justifycontent: "center",
        height: "100vh",
        width: "100vw",
        backgroundcolor: "transparent",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}>

        <img src={LoaderSVG} alt="Loading" />

      </div>
    : 
      <Box >
        メール認証がまだです
      </Box>
    }
    </>
  )
}
const Auth0CallBack = () => {
  const [count, setCount] = useState(1);
  const [email_verified, setEmail_verified] = useState(null);

  useEffect(() => {
    handleAuthentication().then( (data) => {
      var profile = getProfile()
      // console.log(profile);
      // at_hash: "iYgXAJb2pDEabl99s3BAYg"
      // aud: "3LDl0HEZqXUmrXxSNqo9s4UhcPAgOU7I"
      // email: "hnaturalwaver0777@gmail.com"
      // email_verified: false
      // exp: 1625752439
      // iat: 1625716439
      // iss: "https://dev-0u7s8dx0.jp.auth0.com/"
      // name: "hnaturalwaver0777@gmail.com"
      // nickname: "hnaturalwaver0777"
      // nonce: "Us5P8xmzp.Nnl.b6Nt3ofCizcuvz9Gj7"
      // picture: "https://s.gravatar.com/avatar/54cbc7f5df7f21f8e2119eb8f5d8b039?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fhn.png"
      // sub: "auth0|60b491ed344fc20070764088"
      // updated_at: "2021-07-08T03:18:18.944Z"

      //メール認証は済んでいるか？
      setEmail_verified(profile.email_verified);
      if(email_verified){
        //Auth0にログインが成功したら独自DBを見ることができるための　JWT　を取得する
        let params = new URLSearchParams();
        params.append('type', 'retjwt');
        params.append('auth0userid', profile.sub);
        params.append('auth0email', profile.email);

        axios.post('https://apitest.yt-search.net/index.php',params)
        .then(function (response) {
          // console.log(response.data.jwt);
          //　JWT　をsessionStorageに格納
          window.sessionStorage.setItem('jwt', response.data.jwt);
          
        }).catch(function (error) {
          // console.error(error);
        });
        navigate(data[0]);
      }
    })
    
  }, [setCount])

  return (
    
    <Dis email_verified={email_verified} />

  );
}

export default Auth0CallBack