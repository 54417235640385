import React, {   } from "react"
import impAuth0 from 'auth0-js';
import { navigate } from "gatsby"
import { Box, Button } from "@chakra-ui/react"


let idToken = null;
let profile = null;
let expiresAt = null;


export const isBrowser = typeof window !== "undefined"

const auth0 = isBrowser ? new impAuth0.WebAuth({
  domain: process.env.AUTH0_DOMAIN,
  clientID: process.env.AUTH0_CLIENTID,
  redirectUri: process.env.AUTH0_CALLBACK,
  responseType: "token id_token",
  scope: "openid profile email user_metadata",
}):{};

export const getProfile = () => {
  return profile;
}

export const getIdToken = () => {
  return idToken;
}

export const isAuthenticated = () => {
  if(isBrowser){
    if(new Date().getTime() < window.sessionStorage.getItem('expiresAt') && profile !== null){
      return true;
    }
  }
  return false;
}

export const signIn = (routepath) => {
  // console.log("signIn")
  window.sessionStorage.setItem('nowUrl', routepath);
  auth0.authorize();
}

export const signOut = (routepath) => {
  // clear id token, profile, and expiration
  window.sessionStorage.setItem('nowUrl', routepath);
  idToken = null;
  profile = null;
  expiresAt = null;
  window.sessionStorage.removeItem('expiresAt');
  window.sessionStorage.removeItem('jwt');
  navigate(routepath);
}

// export const setJwt = (jwt) => {
//   // clear id token, profile, and expiration
//   window.sessionStorage.setItem('jwt', jwt);
// }

// export const getJwt = () => {
//   // clear id token, profile, and expiration
//   return window.sessionStorage.getItem('jwt');
// }

export const handleAuthentication = () => {
  return new Promise((resolve, reject) => {
    
    auth0.parseHash((err, authResult) => {
      console.log(auth0);
      console.log(window.sessionStorage.getItem('nowUrl'));
      if (err) return reject(err);
      if (!authResult || !authResult.idToken) {
        return reject(err);
      }
      idToken = authResult.idToken;
      profile = authResult.idTokenPayload;
      // set the time that the id token will expire at
      expiresAt = authResult.idTokenPayload.exp * 1000;
      window.sessionStorage.setItem('expiresAt', expiresAt);
      // console.log("handleAuthentication")
      resolve([window.sessionStorage.getItem('nowUrl'),idToken]);
    });
  })
}

export const AuthdisplaySM = ({path}) => {
  return (
    <Box>
      { isAuthenticated() ?
        <Button onClick={()=>signOut(path)}>
          {getProfile() ? getProfile().email.slice( 0, 2 ).toUpperCase () : ""}
        </Button>
        :
        <Button onClick={()=>signIn(path)}>
          ログイン
        </Button>
      }
    </Box>
  )
}

export const Test = ({path}) => {
  return (
    <Box>
      { process.env.AUTH0_DOMAIN }
    </Box>
  )
}